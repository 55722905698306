import React from "react"
import styled from "styled-components"
import Bar from "./bar"
import Recaptcha from 'react-recaptcha';
import LOGO from "../images/CannabisEssentials_Logo.svg"

/* eslint-disable jsx-a11y/accessible-emoji */
const ContactUsWrapperDiv = styled.div`
    background-color: #EAF8F5;
    padding-bottom: 100px;

    #g-recaptcha > div {
        float: right;
    }

    #cannabisess {
        height: 73px;
        margin-bottom: 0;
    }
`;

const ContactWrapperDiv = styled.div `
    display: flex;
    flex-wrap: wrap;
    max-width: 1110px;
    margin: 0 auto;
    padding: 60px 20px;
    box-sizing: border-box;
    width: 100%;

    @media only screen and (max-width: 1140px) {
        width: 100%;
    }
`;

const ContactUs = styled.div`
    width: 50%;
    box-sizing: border-box;
    
    h2 {
        font-size: 28px;
    }
    > div {
        width: 100%;
        max-width: 400px;
    }

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 40px 0px;
    }
`;

const ContactUsForm = styled.div`
    width: 50%;
    box-sizing: border-box;
    padding-top: 100px;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0 5px 0;
    }

    p {
        margin-bottom: 20px;
    }

   form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        @media only screen and (max-width: 1140px) {
            margin-bottom: 0;

            .g-recaptcha > div {
                width: 100% !important;
            }
        }

        > div {
            width: 100%;
            position: relative;
        }
    }

    .radio {
        display:inline-block;
        margin-right: 40px;
    }
    
    input[type='radio'] { 
        transform: scale(1.4); 
        margin-right: 20px;
    }

    input:not([type=checkbox]):not([type=radio]), textarea {
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        border: #919EAB 1px solid;
        margin-bottom: 30px;
        background: #F9FCFF;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        ::placeholder {
            color: #919EAB;
            font-weight: 500;
          }

        :focus, :hover {
            outline: none;
            border-color: #919AA2;
        }
    }

    .has-error input,
    .has-error textarea {
        border-color: #F70545;
    }

    has-error.textarea {
        margin-bottom: 35px;
    }

    .has-error p {
        color: #F70545;
        font-size: 14px;
        position: absolute;
        margin-bottom: 0;
        bottom: 5px;
    }

    .has-error textarea + p {
        @media only screen and (max-width: 400px) {
            bottom: -15px;
        }
    }

    button {
        @media only screen and (min-width: 1140px) {
            max-width: 260px;
        }
    }
`;

const quickconnectSVG = () => (
    <svg width="401px" height="54px" viewBox="0 0 401 54" version="1.1">
    <title>Logo / Concur_QuickConnect</title>
    <defs>
        <polygon id="path-1" points="0 53.4077 400.489 53.4077 400.489 -0.0003 0 -0.0003"></polygon>
    </defs>
    <g id="Website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Concur_QuickConnect---Desktop" transform="translate(-158.000000, -724.000000)">
            <g id="Intro" transform="translate(156.000000, 719.000000)">
                <g id="Concur_QuickConnect" transform="translate(2.000000, 5.000000)">
                    <path d="M22.4303,38.6885 L32.9273,52.7965 L40.6883,52.7935 L35.5323,45.8635 C35.8853,45.3965 36.2203,44.9125 36.5293,44.4025 C38.2293,41.5965 39.0803,38.4425 39.0803,34.9385 C39.0803,31.4355 38.2293,28.2805 36.5293,25.4735 C34.8273,22.6685 32.4993,20.4665 29.5403,18.8675 C26.5803,17.2685 23.2463,16.4685 19.5403,16.4685 C15.8323,16.4685 12.4903,17.2685 9.5153,18.8675 C6.5373,20.4665 4.2083,22.6685 2.5253,25.4735 C0.8413,28.2805 0.0003,31.4355 0.0003,34.9385 C0.0003,38.4425 0.8413,41.5965 2.5253,44.4025 C4.2083,47.2085 6.5373,49.4115 9.5153,51.0095 C12.4903,52.6075 15.8323,53.4075 19.5403,53.4075 C22.8953,53.4075 25.9293,52.7175 28.6723,51.4075 L24.2143,45.4155 C22.7853,46.0395 21.2313,46.3665 19.5403,46.3665 C17.4303,46.3665 15.5263,45.8815 13.8263,44.9125 C12.1243,43.9435 10.7893,42.5905 9.8203,40.8565 C8.8513,39.1215 8.3663,37.1495 8.3663,34.9385 C8.3663,32.7285 8.8513,30.7555 9.8203,29.0205 C10.7893,27.2855 12.1243,25.9345 13.8263,24.9635 C15.5263,23.9945 17.4303,23.5095 19.5403,23.5095 C21.6483,23.5095 23.5533,23.9945 25.2543,24.9635 C26.9543,25.9345 28.2893,27.2855 29.2593,29.0205 C30.2283,30.7555 30.7133,32.7285 30.7133,34.9385 C30.7133,36.2795 30.5133,37.5205 30.1563,38.6855 L22.4303,38.6885 Z" id="Fill-1" fill="#030100"></path>
                    <path d="M67.1408,36.7746 C67.1408,40.0746 66.4588,42.4976 65.0998,44.0446 C63.7388,45.5936 61.7648,46.3666 59.1808,46.3666 C53.8748,46.3666 51.2218,43.1706 51.2218,36.7746 L51.2218,17.0816 L42.9568,17.0816 L42.9568,37.0816 C42.9568,42.3196 44.3768,46.3496 47.2168,49.1726 C50.0568,51.9966 54.0278,53.4076 59.1298,53.4076 C64.2328,53.4076 68.2028,51.9966 71.0428,49.1726 C73.8828,46.3496 75.3028,42.3196 75.3028,37.0816 L75.3028,17.0816 L67.1408,17.0816 L67.1408,36.7746 Z" id="Fill-3" fill="#030100"></path>
                    <g id="Clip-6"></g>
                    <polygon id="Fill-5" fill="#030100" mask="url(#mask-2)" points="80.406 52.7957 88.67 52.7957 88.67 17.0817 80.406 17.0817"></polygon>
                    <path d="M106.7307,24.9638 C108.4657,23.9948 110.4377,23.5098 112.6477,23.5098 C116.2197,23.5098 119.2127,24.9048 121.6277,27.6938 L126.9337,22.7948 C125.2317,20.7548 123.1327,19.1908 120.6327,18.1028 C118.1327,17.0148 115.3347,16.4688 112.2397,16.4688 C108.5667,16.4688 105.2587,17.2608 102.3177,18.8418 C99.3757,20.4238 97.0627,22.6168 95.3787,25.4228 C93.6957,28.2298 92.8537,31.4018 92.8537,34.9388 C92.8537,38.4768 93.6957,41.6468 95.3787,44.4538 C97.0627,47.2598 99.3757,49.4528 102.3177,51.0348 C105.2587,52.6168 108.5497,53.4078 112.1887,53.4078 C115.2837,53.4078 118.0907,52.8618 120.6077,51.7748 C123.1237,50.6868 125.2317,49.1048 126.9337,47.0298 L121.6277,42.1318 C119.2127,44.9558 116.2197,46.3668 112.6477,46.3668 C110.4377,46.3668 108.4657,45.8818 106.7307,44.9128 C104.9957,43.9438 103.6427,42.5908 102.6737,40.8558 C101.7047,39.1218 101.2197,37.1498 101.2197,34.9388 C101.2197,32.7288 101.7047,30.7548 102.6737,29.0208 C103.6427,27.2858 104.9957,25.9338 106.7307,24.9638" id="Fill-7" fill="#030100" mask="url(#mask-2)"></path>
                    <polygon id="Fill-8" fill="#030100" mask="url(#mask-2)" points="164.3927 16.7165 155.1687 16.7165 139.2827 33.0885 139.2827 16.7165 131.0317 16.7165 131.0317 51.7615 139.2827 51.7615 139.2827 42.8995 144.0987 37.9945 155.5797 51.7615 165.2647 51.7615 149.5317 32.3365"></polygon>
                    <path d="M168.9286,43.3759 C170.5516,46.0969 172.7886,48.2239 175.6416,49.7589 C178.4936,51.2949 181.6966,52.0619 185.2506,52.0619 C187.9156,52.0619 190.3826,51.6199 192.6556,50.7349 C194.9276,49.8509 196.8496,48.5749 198.4216,46.9049 L196.0126,44.5519 C193.2446,47.3549 189.7096,48.7579 185.4046,48.7579 C182.5676,48.7579 179.9886,48.1249 177.6656,46.8549 C175.3426,45.5879 173.5226,43.8519 172.2076,41.6489 C171.8696,41.0829 171.5786,40.4979 171.3276,39.8969 L167.3916,39.8969 C167.7826,41.1049 168.2706,42.2739 168.9286,43.3759" id="Fill-9" fill="#F2B31B" mask="url(#mask-2)"></path>
                    <path d="M177.6656,21.6235 C179.9886,20.3545 182.5676,19.7205 185.4046,19.7205 C189.6746,19.7205 193.2106,21.1065 196.0126,23.8765 L198.4216,21.5235 C196.8496,19.8545 194.9366,18.5855 192.6806,17.7185 C190.4266,16.8505 187.9676,16.4165 185.3016,16.4165 C181.7486,16.4165 178.5376,17.1845 175.6676,18.7195 C172.7976,20.2545 170.5506,22.3825 168.9286,25.1025 C168.2136,26.3005 167.6836,27.5735 167.2836,28.8975 L171.2196,28.8975 C171.4966,28.1875 171.8126,27.4925 172.2076,26.8305 C173.5226,24.6265 175.3426,22.8925 177.6656,21.6235" id="Fill-10" fill="#F2B31B" mask="url(#mask-2)"></path>
                    <path d="M231.4249,41.6738 C230.1249,43.8928 228.3329,45.6288 226.0439,46.8808 C223.7549,48.1328 221.1919,48.7578 218.3559,48.7578 C215.5209,48.7578 212.9499,48.1328 210.6439,46.8808 C208.3379,45.6288 206.5249,43.8928 205.2109,41.6738 C203.8949,39.4548 203.2379,36.9768 203.2379,34.2398 C203.2379,31.5028 203.8949,29.0258 205.2109,26.8048 C206.5249,24.5848 208.3379,22.8498 210.6439,21.5978 C212.9499,20.3458 215.5209,19.7208 218.3559,19.7208 C221.1919,19.7208 223.7549,20.3458 226.0439,21.5978 C228.3329,22.8498 230.1249,24.5848 231.4249,26.8048 C232.7219,29.0258 233.3719,31.5028 233.3719,34.2398 C233.3719,36.9768 232.7219,39.4548 231.4249,41.6738 M227.9649,18.7198 C225.1129,17.1848 221.9099,16.4168 218.3559,16.4168 C214.8029,16.4168 211.5829,17.1928 208.6959,18.7438 C205.8089,20.2968 203.5539,22.4238 201.9319,25.1278 C200.3079,27.8318 199.4969,30.8688 199.4969,34.2398 C199.4969,37.6108 200.3079,40.6468 201.9319,43.3518 C203.5539,46.0548 205.8089,48.1828 208.6959,49.7338 C211.5829,51.2858 214.8029,52.0618 218.3559,52.0618 C221.9099,52.0618 225.1129,51.2948 227.9649,49.7588 C230.8179,48.2238 233.0649,46.0968 234.7039,43.3758 C236.3439,40.6558 237.1649,37.6108 237.1649,34.2398 C237.1649,30.8688 236.3439,27.8238 234.7039,25.1028 C233.0649,22.3828 230.8179,20.2548 227.9649,18.7198" id="Fill-11" fill="#F2B31B" mask="url(#mask-2)"></path>
                    <polygon id="Fill-12" fill="#F2B31B" mask="url(#mask-2)" points="266.7855 45.1527 243.6735 16.7167 240.5465 16.7167 240.5465 51.7617 244.3385 51.7617 244.3385 23.3257 267.4005 51.7617 270.5255 51.7617 270.5255 16.7167 266.7855 16.7167"></polygon>
                    <polygon id="Fill-13" fill="#F2B31B" mask="url(#mask-2)" points="301.2742 45.1527 278.1622 16.7167 275.0352 16.7167 275.0352 51.7617 278.8272 51.7617 278.8272 23.3257 301.8892 51.7617 305.0142 51.7617 305.0142 16.7167 301.2742 16.7167"></polygon>
                    <polygon id="Fill-14" fill="#F2B31B" mask="url(#mask-2)" points="313.3157 35.5406 331.8687 35.5406 331.8687 32.3876 313.3157 32.3876 313.3157 19.9216 334.1237 19.9216 334.1237 16.7166 309.5237 16.7166 309.5237 51.7616 334.8907 51.7616 334.8907 48.5566 313.3157 48.5566"></polygon>
                    <path d="M348.6249,21.6235 C350.9479,20.3545 353.5269,19.7205 356.3639,19.7205 C360.6339,19.7205 364.1699,21.1065 366.9719,23.8765 L369.3809,21.5235 C367.8089,19.8545 365.8959,18.5855 363.6399,17.7185 C361.3859,16.8505 358.9269,16.4165 356.2609,16.4165 C352.7079,16.4165 349.4969,17.1845 346.6269,18.7195 C343.7569,20.2545 341.5099,22.3825 339.8879,25.1025 C338.2639,27.8235 337.4529,30.8685 337.4529,34.2395 C337.4529,37.6105 338.2639,40.6565 339.8879,43.3755 C341.5099,46.0975 343.7479,48.2235 346.6009,49.7585 C349.4529,51.2945 352.6559,52.0615 356.2099,52.0615 C358.8749,52.0615 361.3419,51.6205 363.6149,50.7355 C365.8869,49.8515 367.8089,48.5745 369.3809,46.9045 L366.9719,44.5515 C364.2039,47.3555 360.6689,48.7575 356.3639,48.7575 C353.5269,48.7575 350.9479,48.1245 348.6249,46.8545 C346.3019,45.5875 344.4819,43.8515 343.1669,41.6495 C341.8519,39.4455 341.1939,36.9765 341.1939,34.2395 C341.1939,31.5035 341.8519,29.0325 343.1669,26.8305 C344.4819,24.6265 346.3019,22.8925 348.6249,21.6235" id="Fill-15" fill="#F2B31B" mask="url(#mask-2)"></path>
                    <polygon id="Fill-16" fill="#F2B31B" mask="url(#mask-2)" points="371.483 16.7165 371.483 19.9215 384.089 19.9215 384.089 51.7615 387.881 51.7615 387.881 19.9215 400.489 19.9215 400.489 16.7165"></polygon>
                    <path d="M184.3855,27.8598 C181.4625,27.8598 178.9835,29.6948 177.9935,32.2688 L152.8655,32.2688 L152.2245,32.9428 L155.8075,37.5768 L178.1595,37.5768 C179.2435,39.9388 181.6155,41.5878 184.3855,41.5878 C188.1765,41.5878 191.2495,38.5148 191.2495,34.7238 C191.2495,30.9328 188.1765,27.8598 184.3855,27.8598" id="Fill-17" fill="#030100" mask="url(#mask-2)"></path>
                    <path d="M5.3737,11.1098 C4.4227,10.5988 3.6777,9.8898 3.1367,8.9838 C2.5957,8.0768 2.3257,7.0618 2.3257,5.9388 C2.3257,4.8158 2.5957,3.8008 3.1367,2.8948 C3.6777,1.9878 4.4257,1.2788 5.3827,0.7678 C6.3387,0.2558 7.4077,-0.0002 8.5927,-0.0002 C9.4797,-0.0002 10.3007,0.1448 11.0517,0.4338 C11.8027,0.7228 12.4397,1.1458 12.9637,1.7018 L12.1607,2.4858 C11.2277,1.5628 10.0497,1.1008 8.6267,1.1008 C7.6817,1.1008 6.8217,1.3128 6.0477,1.7348 C5.2737,2.1578 4.6677,2.7358 4.2287,3.4698 C3.7907,4.2038 3.5717,5.0268 3.5717,5.9388 C3.5717,6.8508 3.7907,7.6738 4.2287,8.4078 C4.6677,9.1418 5.2737,9.7198 6.0477,10.1428 C6.8217,10.5658 7.6817,10.7768 8.6267,10.7768 C10.0607,10.7768 11.2387,10.3098 12.1607,9.3748 L12.9637,10.1588 C12.4397,10.7148 11.7997,11.1408 11.0427,11.4358 C10.2857,11.7298 9.4637,11.8778 8.5747,11.8778 C7.3917,11.8778 6.3237,11.6218 5.3737,11.1098" id="Fill-18" fill="#030100" mask="url(#mask-2)"></path>
                    <path d="M24.2175,10.1508 C24.9805,9.7338 25.5775,9.1558 26.0105,8.4158 C26.4435,7.6758 26.6585,6.8508 26.6585,5.9388 C26.6585,5.0268 26.4435,4.2008 26.0105,3.4618 C25.5775,2.7218 24.9805,2.1438 24.2175,1.7268 C23.4545,1.3098 22.6005,1.1008 21.6555,1.1008 C20.7105,1.1008 19.8545,1.3098 19.0855,1.7268 C18.3175,2.1438 17.7135,2.7218 17.2765,3.4618 C16.8375,4.2008 16.6185,5.0268 16.6185,5.9388 C16.6185,6.8508 16.8375,7.6758 17.2765,8.4158 C17.7135,9.1558 18.3175,9.7338 19.0855,10.1508 C19.8545,10.5678 20.7105,10.7758 21.6555,10.7758 C22.6005,10.7758 23.4545,10.5678 24.2175,10.1508 M18.4375,11.1018 C17.4755,10.5848 16.7235,9.8758 16.1835,8.9748 C15.6425,8.0738 15.3715,7.0618 15.3715,5.9388 C15.3715,4.8158 15.6425,3.8038 16.1835,2.9028 C16.7235,2.0018 17.4755,1.2928 18.4375,0.7758 C19.3995,0.2588 20.4715,-0.0002 21.6555,-0.0002 C22.8395,-0.0002 23.9065,0.2558 24.8585,0.7678 C25.8085,1.2788 26.5565,1.9878 27.1035,2.8948 C27.6495,3.8008 27.9225,4.8158 27.9225,5.9388 C27.9225,7.0618 27.6495,8.0768 27.1035,8.9838 C26.5565,9.8898 25.8085,10.5988 24.8585,11.1098 C23.9065,11.6218 22.8395,11.8778 21.6555,11.8778 C20.4715,11.8778 19.3995,11.6188 18.4375,11.1018" id="Fill-19" fill="#030100" mask="url(#mask-2)"></path>
                    <polygon id="Fill-20" fill="#030100" mask="url(#mask-2)" points="41.7712 0.1001 41.7712 11.7771 40.7292 11.7771 33.0452 2.3021 33.0452 11.7771 31.7822 11.7771 31.7822 0.1001 32.8232 0.1001 40.5252 9.5751 40.5252 0.1001"></polygon>
                    <path d="M48.6784,11.1098 C47.7274,10.5988 46.9824,9.8898 46.4414,8.9838 C45.9004,8.0768 45.6304,7.0618 45.6304,5.9388 C45.6304,4.8158 45.9004,3.8008 46.4414,2.8948 C46.9824,1.9878 47.7304,1.2788 48.6874,0.7678 C49.6434,0.2558 50.7124,-0.0002 51.8974,-0.0002 C52.7844,-0.0002 53.6054,0.1448 54.3564,0.4338 C55.1074,0.7228 55.7444,1.1458 56.2684,1.7018 L55.4654,2.4858 C54.5324,1.5628 53.3544,1.1008 51.9314,1.1008 C50.9864,1.1008 50.1264,1.3128 49.3524,1.7348 C48.5784,2.1578 47.9724,2.7358 47.5334,3.4698 C47.0954,4.2038 46.8764,5.0268 46.8764,5.9388 C46.8764,6.8508 47.0954,7.6738 47.5334,8.4078 C47.9724,9.1418 48.5784,9.7198 49.3524,10.1428 C50.1264,10.5658 50.9864,10.7768 51.9314,10.7768 C53.3654,10.7768 54.5434,10.3098 55.4654,9.3748 L56.2684,10.1588 C55.7444,10.7148 55.1044,11.1408 54.3474,11.4358 C53.5904,11.7298 52.7684,11.8778 51.8794,11.8778 C50.6964,11.8778 49.6284,11.6218 48.6784,11.1098" id="Fill-21" fill="#030100" mask="url(#mask-2)"></path>
                    <path d="M61.1521,10.5761 C60.2871,9.7091 59.8541,8.4471 59.8541,6.7891 L59.8541,0.1001 L61.1181,0.1001 L61.1181,6.7391 C61.1181,8.0961 61.4311,9.1081 62.0571,9.7751 C62.6831,10.4431 63.5891,10.7761 64.7721,10.7761 C65.9671,10.7761 66.8791,10.4431 67.5051,9.7751 C68.1311,9.1081 68.4441,8.0961 68.4441,6.7391 L68.4441,0.1001 L69.6731,0.1001 L69.6731,6.7891 C69.6731,8.4471 69.2441,9.7091 68.3841,10.5761 C67.5241,11.4441 66.3201,11.8771 64.7721,11.8771 C63.2241,11.8771 62.0171,11.4441 61.1521,10.5761" id="Fill-22" fill="#030100" mask="url(#mask-2)"></path>
                    <path d="M81.6609,6.2558 C82.2869,5.7438 82.5999,5.0268 82.5999,4.1038 C82.5999,3.1588 82.2869,2.4328 81.6609,1.9268 C81.0349,1.4208 80.1299,1.1678 78.9459,1.1678 L75.7689,1.1678 L75.7689,7.0228 L78.9459,7.0228 C80.1299,7.0228 81.0349,6.7678 81.6609,6.2558 L81.6609,6.2558 Z M82.6679,11.7768 L79.9359,8.0238 C79.6289,8.0568 79.3089,8.0738 78.9799,8.0738 L75.7689,8.0738 L75.7689,11.7768 L74.5059,11.7768 L74.5059,0.0998 L78.9799,0.0998 C80.5059,0.0998 81.7009,0.4558 82.5659,1.1678 C83.4309,1.8798 83.8639,2.8588 83.8639,4.1038 C83.8639,5.0158 83.6269,5.7858 83.1549,6.4138 C82.6819,7.0428 82.0079,7.4958 81.1319,7.7738 L84.0519,11.7768 L82.6679,11.7768 Z" id="Fill-23" fill="#030100" mask="url(#mask-2)"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)

class ContactUsPage extends React.Component {
    state = {
        email: '',
        first_name: '',
        last_name: '',
        description: '',
        formErrors: {
            email: '',
            first_name: '',
            last_name: '',
            description: ''
        },
        emailValid: false,
        firstNameValid: false,
        lastNameValid: false,
        descriptionValid: false,
        formValid: false
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({[name]: value},
            () => { this.validateField(name, value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;
        let descriptionValid = this.state.descriptionValid;

        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid.';
            break;
          case 'first_name':
            firstNameValid = value.length > 0;
            fieldValidationErrors.first_name = firstNameValid ? '': ' is required.';
            break;
          case 'last_name':
                lastNameValid = value.length > 0;
                fieldValidationErrors.last_name = lastNameValid ? '': ' is required.';
            break;
          case 'description':
                descriptionValid = value.length > 12;
                fieldValidationErrors.description = descriptionValid ? '': ' must be longer than 12 characters.';
            break;

            default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        emailValid: emailValid,
                        firstNameValid: firstNameValid,
                        lastNameValid: lastNameValid,
                        descriptionValid: descriptionValid
                      }, this.validateForm);
      }

      handleSubmit = event => {
        document.querySelectorAll('.validate').forEach((input) => {
            const value = input.value
            const name = input.name
            this.setState({[name]: value},
                () => { this.validateField(name, value) });
        })

        if (!this.state.formValid) event.preventDefault();
      }

      validateForm() {
        this.setState({formValid: this.state.emailValid && this.state.firstNameValid && this.state.lastNameValid &&  this.state.descriptionValid});
      }

      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
     }

     errorMessage(name, error) {
        return(error.length === 0 ? '' : <p key={name}>{name} {error}</p>);
     }

     callback() {
         document.querySelector('#submit').removeAttribute('disabled');
     }

      render() {
        return (
            <ContactUsWrapperDiv>
            
            <ContactWrapperDiv>
                <ContactUs>
                    <div>
                        <img className="Logo" src={LOGO} alt="Cannabis Essential Logo" id="cannabisess"/>
                        <h2 className="margin-top-40">Request a demo of Cannabis <br/>Essentials ERP</h2>
                        <p>Please provide your contact information and we will work with you to arrange a demonstration tailored to your organizations needs.</p>
                    </div>
                </ContactUs>
                <ContactUsForm>
                    <Bar/>
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" noValidate onSubmit={this.handleSubmit}> 
                        <input type="hidden" name="oid" value="00D36000000pbA2"/>
                        <input type="hidden" name="retURL" value="https://www.laddpartners.com/solutions/cannabisessentials-demo/?submit=success"/>
                        <input type="hidden" name="lead_source" value="WebPage-Cannabis"/>

                        <div className={`form-group ${this.errorClass(this.state.formErrors.first_name)}`}>
                            <input id="first_name" className="validate" maxLength="40" name="first_name" size="20" placeholder="Your First Name" required
                                            onChange={this.handleInputChange}
                                            value={this.state.first_name}
                                            ></input>
                            {this.errorMessage('First Name', this.state.formErrors.first_name)}
                        </div>
                        <div className={`form-group ${this.errorClass(this.state.formErrors.last_name)}`}>
                            <input id="last_name" className="validate" maxLength="80" name="last_name" size="20" placeholder="Your Last Name" required
                                            onChange={this.handleInputChange}
                                            value={this.state.last_name}
                                            ></input>
                            {this.errorMessage('Last Name', this.state.formErrors.last_name)}
                        </div>
                        <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                            <input id="email" className="validate" maxLength="80" name="email" size="20"  placeholder="Your Email" required
                                              onChange={this.handleInputChange}
                                              value={this.state.email}
                                              ></input>
                            {this.errorMessage('Email', this.state.formErrors.email)}
                        </div>
                        <div>
                            <input id="company" maxLength="40" name="company" size="20" placeholder="Your Company"
                                            onBlur={this.handleInputChange}></input>
                        </div>

                        <div className={`form-group textarea ${this.errorClass(this.state.formErrors.description)}`}>
                            <textarea className="validate" placeholder="Message" name="description" rows="7" required
                            onChange={this.handleInputChange}
                            value={this.state.description}
                            ></textarea>
                            {this.errorMessage('Message', this.state.formErrors.description)}

                        </div>

                        <div>
                        <Recaptcha
                            sitekey="6LcnBfEUAAAAAA0ZZbvkTUH4USvHiNvF9DHHK77f"
                            render="explicit"
                            verifyCallback={this.callback}
                        />
                        </div>
                        <div><button id="submit" type="submit" disabled>Submit Request</button></div>
                    </form>
                </ContactUsForm>
            </ContactWrapperDiv>
        </ContactUsWrapperDiv>
        )
      }
}

export default ContactUsPage