import React from "react"
import Hero from "../../components/hero-demo-cannabis"
import Form from "../../components/form-demo-cannabis";

import "../../assets/index.css"
import Layout from "../../layouts/layout-contactus"

export default () => (
    <Layout title="Cannabis Essentials Demo | Ladd Partners">
        <Hero/>
        <Form/>
    </Layout>
)
